// Helper function to record Amplify analytics

import recordAnalytics from '@/utils/analytics/recordAnalytics'

export default function recordAnalyticsMulti(
  eventBody,
  data
) {
  data?.forEach(content => recordAnalytics(eventBody, {
    component: content.componentId,
    productModelBusinessName: content.businessName,
    status: content.status
  }))
}
