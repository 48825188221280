<template>
  <mi-dialog
    :model-value="isNewValidationDialogShown"
    class="new-validation-dialog"
    no-route-dismiss
    persistent
    @update:model-value="SET_IS_NEW_VALIDATION_DIALOG_SHOWN"
  >
    <!-- Title -->
    <h5 class="q-mt-none q-mb-sm">
      New {{ isBomValidationsRoute ? 'BOM' : 'Test Case' }} validation
    </h5>

    <!-- Subtitle -->
    <p class="q-mb-lg"> Define details for the validation. </p>

    <!-- Form -->
    <validation-dialog-form
      v-if="isNewValidationDialogShown"
      :is-bom-validations-route="isBomValidationsRoute"
      :loading="isValidationInProgress"
      :component-sequence-initial-value="failedComponentsSequence"
      @submit="handleValidationFormSubmit"
    ></validation-dialog-form>
  </mi-dialog>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import notify from '@/utils/notify'

  import { triggerBOMValidation, validateTestCase } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { SET_IS_NEW_VALIDATION_DIALOG_SHOWN, SET_BOM_VALIDATION_COMPONENTS }
    from '@/store/modules/validations/mutationTypes'
  import { BOM_VALIDATIONS_ROUTE, BOM_VALIDATION_RESULTS_ROUTE } from '@/router/routeNames'
  import { BOM_CREATE, TEST_CASES_CREATE } from '@/utils/analytics/constants'

  import ValidationDialogForm from './NewValidationDialogForm.vue'

  const { mapActions, mapMutations, mapState } = createNamespacedHelpers('validations')

  export default {
    name: 'NewValidationDialog',
    components: { ValidationDialogForm },
    data: () => ({
      isValidationInProgress: false,
      failedComponentsSequence: ''
    }),
    computed: {
      ...mapState(['isNewValidationDialogShown']),

      isBomValidationsRoute() {
        return !!this.$route.matched?.some(
          route => [BOM_VALIDATION_RESULTS_ROUTE.name, BOM_VALIDATIONS_ROUTE.name].includes(route.name)
        )
      }
    },
    methods: {
      ...mapActions(['getBomValidationsList', 'getTestCaseValidationsList']),
      ...mapMutations({ SET_IS_NEW_VALIDATION_DIALOG_SHOWN, SET_BOM_VALIDATION_COMPONENTS }),

      async handleValidationFormSubmit(params = {}) {
        this.isValidationInProgress = true

        try {
          await (this.isBomValidationsRoute
            ? this.performBomValidation(params)
            : this.performTestCaseValidation(params)
          )
        }
        finally {
          this.isValidationInProgress = false
        }
      },
      numberOfValidComponents(arrayOfComponents) {
        if (arrayOfComponents && arrayOfComponents.filter(component => component.status === 'FAILED').length === 0) {
          notify({
            title: 'COMPLETED',
            content: `${ arrayOfComponents.filter(component => component.status === 'IN_PROGRESS').length }
            component(s) were successfully requested.`,
            type: 'dark',
            closeIcon: false
          })
          this.SET_IS_NEW_VALIDATION_DIALOG_SHOWN()
        }
        else {
          notify({
            title: 'Components to be reviewed',
            content: 'Change the remaining components. They are not found in this product model.',
            type: 'dark_no_icon',
            closeIcon: false,
            position: 'center'
          })
        }
      },
      getTheFailedComponents(arrayOfComponents) {
        this.failedComponentsSequence = arrayOfComponents.length
          ? arrayOfComponents.filter(component => component.status === 'FAILED')
            .map(component => component.componentId) : []
      },
      async performBomValidation(requestParams = {}) {
        const componentsId = await triggerBOMValidation(requestParams)
        this.SET_BOM_VALIDATION_COMPONENTS(componentsId)
        this.getTheFailedComponents(componentsId)
        this.numberOfValidComponents(componentsId)

        this.$router.replace({ name: BOM_VALIDATIONS_ROUTE.name })
        this.getBomValidationsList()

        // Analytics
        recordAnalytics(BOM_CREATE)
      },
      async performTestCaseValidation(requestParams = {}) {
        await validateTestCase(requestParams)

        this.SET_IS_NEW_VALIDATION_DIALOG_SHOWN()
        this.getTestCaseValidationsList(requestParams.testCaseId)

        // Analytics
        recordAnalytics(TEST_CASES_CREATE)
      }
    }
  }
</script>

<style lang="scss">
  .new-validation-dialog .q-dialog__inner > .mi-card {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    min-height: 565px;
  }

  @media (max-width: $mi-responsive-large-width) {
    .new-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
        min-height: 565px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .new-validation-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
        min-height: 565px;
      }
    }
  }

  ::v-deep(.mi-notification--dark_no_icon) {
    margin: 20rem 20rem 0 0;
  }
</style>
