<template>
  <page-layout-with-top-bar>
    <!-- Validations header -->
    <template
      #top-bar
    >
      <validations-header></validations-header>
    </template>
    <router-view></router-view>

    <!-- New validation dialog -->
    <new-validation-dialog></new-validation-dialog>
  </page-layout-with-top-bar>
</template>

<script>
  import PageLayoutWithTopBar from '@/layouts/PageLayoutWithTopBar.vue'

  import NewValidationDialog from '@/components/validations/new-validation-dialog/NewValidationDialog.vue'
  import ValidationsHeader from '@/components/validations/ValidationsHeader.vue'

  export default {
    name: 'Validations',
    components: {
      PageLayoutWithTopBar,
      NewValidationDialog,
      ValidationsHeader
    }
  }
</script>
