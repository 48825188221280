<template>
  <mi-refresh-btn
    :is-loading="areTestCasesLoading"
    class="test-case-filter__refresh-btn"
    @clicked-refresh-btn="handleRefreshBtnChange"
  ></mi-refresh-btn>
  <div class="test-case-filter__divider"></div>
  <mi-btn
    class="test-case-filter__filter-btn--my-files test-case-filter__creator-filter-btn"
    :class="activeFilters.has(testCaseValidationsFilters.USER.key) ? 'test-case-filter__filter-btn--selected' : ''"
    flat
    @click="handleFilter(testCaseValidationsFilters.USER.key, user.username)"
  >
    Mine only
  </mi-btn>
  <div class="test-case-filter__divider"></div>
  <mi-btn
    class="test-case-filter__filter-btn--today-files test-case-filter__creator-filter-btn"
    :class="activeFilters.has(testCaseValidationsFilters.DATE.key) ? 'test-case-filter__filter-btn--selected' : ''"
    flat
    @click="handleFilter(testCaseValidationsFilters.DATE.key, currentDate)"
  >
    From today
  </mi-btn>
</template>

  <script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { BOM_VALIDATION_FILTER, VALIDATIONS_FILTERS } from '@/constants'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import { formatDateRequest } from '@/utils/formatDate'

  import {
    SET_FILTER_TEST_CASES,
    SET_TEST_CASE_FILTERS,
    ARE_TEST_CASES_LOADING
  }
    from '@/store/modules/validations/mutationTypes'

  export default {
    name: 'TestCaseFiltersBar',
    components: { MiBtn },
    emits: ['clicked-refresh-btn'],
    data: () => ({
      activeFilter: '',
      activeFilters: new Map(),
      testCaseValidationsFilters: VALIDATIONS_FILTERS,
      filterOptions: {
        [BOM_VALIDATION_FILTER.USER.key]: {},
        [BOM_VALIDATION_FILTER.DATE.key]: {}
      },
      currentDate: new Date().toISOString().substring(0, 10)
    }),
    computed: {
      ...mapState(
        'validations', ['isBomValidationLoading', 'areTestCasesLoading', 'testCaseFilters']
      ),
      ...mapState(['user'])
    },
    methods: {
      ...mapActions('validations', ['getBomValidationsList', 'getTestCasesList']),
      ...mapMutations(
        'validations',
        {
          SET_FILTER_TEST_CASES,
          ARE_TEST_CASES_LOADING,
          SET_TEST_CASE_FILTERS
        }
      ),

      async handleRefreshBtnChange() {
        this.SET_TEST_CASE_FILTERS({ type: 'refresh', val: !this.testCaseFilters.refresh })
      },
      handleClick() {
        this.$emit('clicked-refresh-btn', true)
      },
      handleFilter(type, val) {
        const filterType = (VALIDATIONS_FILTERS.USER.key === type)
          ? VALIDATIONS_FILTERS.USER.key
          : VALIDATIONS_FILTERS.DATE.key

        const hasFilter = this.activeFilters.has(filterType)

        if (hasFilter) {
          this.activeFilters.delete(filterType)
          this.SET_TEST_CASE_FILTERS({ type, val: '' })
          return
        }

        this.activeFilters.set(filterType)

        const newVal = type === this.testCaseValidationsFilters.DATE.key
          ? formatDateRequest(new Date(val).toISOString()) : val
        this.SET_TEST_CASE_FILTERS({ type, val: newVal })
      },
      setFilters(type, val) {
        const filterOptions = {
          [BOM_VALIDATION_FILTER.USER.key]: { ...BOM_VALIDATION_FILTER.USER, value: val.toString() },
          [BOM_VALIDATION_FILTER.DATE.key]: { ...BOM_VALIDATION_FILTER.DATE, value: val.toString() }
        }

        this.SET_FILTER_TEST_CASES(filterOptions[type])
      }
    }
  }
  </script>

  <style lang="scss">
    .test-case-filter {
      width: 30%;

      &__refresh-btn {
        width: 48px;
        height: 48px;
        padding-right: 1rem;
      }

      &__divider {
        border: 1px solid $mi-anthracite-50;
        height: 27px;
      }

      &__filter-btn {
        margin: 0 16px;

        &--selected {
          /* stylelint-disable declaration-no-important */
          background-color: $mi-anthracite-800 !important;
          color: #ffffff !important;
        }

        &--my-files {
          width: 250px;
        }

        &--today-files {
          width: 210px;
        }
      }

      &__basic-filter-btn {
        margin-left: 0;
      }
    }
  </style>
