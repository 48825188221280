<template>
  <mi-form @submit="emitFormData">
    <!-- Product model -->
    <mi-select
      v-model="validationForm.productModel"
      :display-value="validationForm.productModel?.businessName || 'Select Product Model'"
      :options="productModels"
      :rules="productModelValidationRules"
      class="q-mb-sm"
      label="Product Model"
      option-label="businessName"
      options-selected-class="text-accent"
      outlined
    ></mi-select>

    <template v-if="isBomValidationsRoute">
      <!-- Component ID -->
      <mi-select
        ref="chips"
        v-model="validationForm.componentsID"
        label="Component ID's:"
        outlined
        use-input
        hint="Add up to 20 components by using the delimiter ‘;’ press enter to confirm the components"
        use-chips
        multiple
        :error="selectError.invalid"
        :error-message="selectError.message"
        hide-dropdown-icon
        input-debounce="0"
        new-value-mode="add-unique"
        class="chips-input"
        data-cy="components-select"
        @new-value="createValue"
      >
      </mi-select>

      <div class="column inline q-mb-lg">
        <!-- LE and T validations toggle -->
        <mi-checkbox
          v-model="validationForm.isLEAndTValidationEnabled"
          class="q-mb-sm"
          label="Add LE and T validations"
          color="accent"
        ></mi-checkbox>

        <!-- Planning period range toggle -->
        <mi-checkbox
          v-model="validationForm.isPlanningPeriodRangeEnabled"
          color="accent"
        >
          Add Planning Period range
          <mi-icon class="q-ml-xs" name="info-circle" size="1rem">
            <mi-tooltip :offset="[5, 5]" anchor="bottom middle" self="top middle">
              The calculation of a PP range can take long to conclude. <br />
              The results might be incomplete since the CoRE is limiting the response to a certain amount of data.
            </mi-tooltip>
          </mi-icon>
        </mi-checkbox>
      </div>
    </template>

    <!-- Planning period range toggle -->
    <div class="q-mb-md">
      <span class="inline-block text-overline text-weight-bold text-primary text-family-normal q-mb-xs">
        Planning Period
      </span>

      <planning-period-range
        v-model:from="validationForm.initialPP"
        v-model:to="validationForm.finalPP"
        :disable-to="isBomValidationsRoute && !validationForm.isPlanningPeriodRangeEnabled"
        class="no-wrap"
      ></planning-period-range>
    </div>

    <!-- Action buttons -->
    <div class="row justify-end">
      <mi-btn v-close-popup flat> Cancel </mi-btn>
      <mi-btn :loading="loading" class="q-ml-sm" type="submit"> Validate </mi-btn>
    </div>
  </mi-form>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import PlanningPeriodRange from '@/components/base/PlanningPeriodRange.vue'
  import { required } from '@/utils/validators'

  const { mapState, mapActions } = createNamespacedHelpers('product-model')

  export default {
    name: 'NewValidationDialogForm',
    components: { PlanningPeriodRange },
    props: {
      isBomValidationsRoute: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      componentSequenceInitialValue: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: ['submit'],
    data: () => ({
      storageKey: process.env.VUE_APP_STORAGE_KEY_NEW_VALIDATION,
      selectError: {
        invalid: false,
        message: '',
        maxComponentsLimit: 20,
        rules: {
          separator: 'User IDs must be separated by ;',
          maxComponents: 'You can only validate up to 20 components at a time'
        }
      },
      validationForm: {
        productModel: null,
        initialPP: '',
        finalPP: '',
        componentsID: null,
        componentSequence: '',
        isLEAndTValidationEnabled: false,
        isPlanningPeriodRangeEnabled: false
      },
      errorMessage: '',
      productModelValidationRules: [required]
    }),
    computed: {
      ...mapState(['productModels', 'areProductModelsLoading'])
    },
    watch: {
      componentSequenceInitialValue(componentSequenceInitialValue) {
        const config = this.$q.localStorage.getItem(this.storageKey)
        if (!componentSequenceInitialValue) {
          this.validationForm = { ...this.validationForm, ...config }
        }
        else {
          this.validationForm = {
            ...this.validationForm,
            ...config,
            componentsID: componentSequenceInitialValue
          }
        }
      },
      errorMessage() {
        this.$nextTick(() => {
          this.$refs.componentIdField.validate()
        })
      }

    },
    async created() {
      this.setLatestSubmission()
      await this.getProductModelsList()
      this.$refs.chips.$el.querySelector('input').addEventListener(
        'change', e => this.createValueFromListener(e)
      )
    },
    beforeUnmount() {
      this.$refs.chips.$el.querySelector('input').removeEventListener(
        'change', this.createValueFromListener
      )
    },
    methods: {
      ...mapActions(['getProductModelsList']),

      createValueFromListener(e) {
        this.createValue(e.target.value)
      },
      setLatestSubmission() {
        if (!this.$q.localStorage.has(this.storageKey)) return

        const config = this.$q.localStorage.getItem(this.storageKey)
        this.validationForm = {
          ...this.validationForm,
          ...config,
          componentsID: config.componentList
        }
        this.selectedBusinessName = config.selectedBusinessName
      },
      saveLatestSubmission(data) {
        if (this.$q.localStorage) this.$q.localStorage.set(this.storageKey, data)
      },
      emitFormData() {
        const {
          componentSequence,
          componentsID,
          initialPP,
          finalPP,
          productModel,
          isLEAndTValidationEnabled,
          isPlanningPeriodRangeEnabled
        } = this.validationForm
        const formData = {
          encodedBusinessName: productModel.encodedBusinessName || ''
        }

        if (this.isBomValidationsRoute) {
          formData.componentList = componentsID
          formData.isLEAndTValidationEnabled = isLEAndTValidationEnabled
          formData.finalPP = isPlanningPeriodRangeEnabled ? finalPP : null
          formData.isPlanningPeriodRangeEnabled = isPlanningPeriodRangeEnabled
        }
        else {
          formData.testCaseId = this.$route.params.testCaseId || ''
          formData.finalPP = finalPP
        }
        formData.productModelId = productModel.id
        formData.initialPP = initialPP
        formData.productModel = productModel
        formData.componentSequence = componentSequence
        this.saveLatestSubmission(formData)
        this.$emit('submit', formData)
      },
      createValue(val, done) {
        const componentsID = this.validationForm.componentsID || []
        const valuesList = val.toUpperCase()
          .split(';')

        this.validateSelection(componentsID, val, valuesList)

        if (this.selectError.invalid) return

        valuesList.map(v => v.trim())
          .filter(v => v.length > 0 && v.length < 5)
          .forEach(v => componentsID.push(v))
        this.validationForm.componentsID = [...new Set(componentsID)]
        this.$nextTick(() => {
          done?.([...this.validationForm.componentsID].pop())
        })
      },
      validateSelection(components, selectedValue, values) {
        const hasSpecialChar = this.containsSpecialChars(selectedValue)
        const isLimitReached = this.isComponentLimitReached(components, values)

        this.selectError.invalid = hasSpecialChar || isLimitReached

        if (hasSpecialChar) {
          this.selectError.message = this.selectError.rules.separator
        }

        if (isLimitReached) {
          this.selectError.message = this.selectError.rules.maxComponents
        }
      },
      containsSpecialChars(str) {
        const regex = /[!@#$%^&*()_+\-=[\]{}':"\\|,.<>/?]/
        return regex.test(str)
      },
      isComponentLimitReached(components, values) {
        return components.length + values.length > this.selectError.maxComponentsLimit
      }
    }
  }
</script>

<style lang="scss">
  .chips-input {
    padding-bottom: 50px;
  }

  .q-chip--dense .q-chip__icon {
    font-size: 10px;
    padding: 2px;
    background-color: $mi-anthracite-800;
    border-radius: 50%;
    color: $mi-white;
    margin-left: 4px;
  }

  .q-chip {
    border: 2px solid $mi-anthracite-800;
  }
</style>
