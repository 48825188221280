<template>
  <mi-refresh-btn
    :is-loading="isBomValidationLoading"
    class="validations-filter__refresh-btn"
    @clicked-refresh-btn="refreshBOMValidations"
  ></mi-refresh-btn>
  <div class="validations-filter__divider"></div>
  <mi-btn
    class="validations-filter__filter-btn--my-files validations-filter__creator-filter-btn"
    :class="activeFilters.has(bomValidationsFilters.USER.key) ? 'validations-filter__filter-btn--selected' : ''"
    flat
    @click="handleFilter(bomValidationsFilters.USER.key, user.username)"
  >
    My validations
  </mi-btn>
  <div class="validations-filter__divider"></div>
  <mi-btn
    class="validations-filter__filter-btn--today-files validations-filter__creator-filter-btn"
    :class="activeFilters.has(bomValidationsFilters.DATE.key) ? 'validations-filter__filter-btn--selected' : ''"
    flat
    @click="handleFilter(bomValidationsFilters.DATE.key, currentDate)"
  >
    From today
  </mi-btn>
  <div class="validations-filter__divider"></div>
</template>

<script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import { BOM_VALIDATION_FILTER, VALIDATIONS_FILTERS } from '@/constants'
  import { mapActions, mapMutations, mapState } from 'vuex'
  import {
    SET_CURRENT_BOM_VALIDATION,
    SET_IN_PROGRESS_BOM_VALIDATIONS,
    SET_BOM_VALIDATION_IS_LOADING,
    SET_FILTERED_USER_VALIDATIONS,
    HANDLE_INITIAL_STATE_SELECTED_EXPORT,
    SET_FILTER_USER_VALIDATIONS
  }
    from '@/store/modules/validations/mutationTypes'
  import state from '@/store/modules/validations/state'
  import getters from '@/store/modules/validations/getters'
  import recordAnalyticsMulti from '@/utils/analytics/recordAnalyticsMulti'
  import { BOM_UPDATE_STATUS } from '@/utils/analytics/constants'

  export default {
    name: 'ValidationsFiltersBar',
    components: { MiBtn },
    emits: ['clicked-refresh-btn'],
    data: () => ({
      activeFilter: '',
      activeFilters: new Map(),
      bomValidationsFilters: VALIDATIONS_FILTERS,
      filterOptions: {
        [BOM_VALIDATION_FILTER.USER.key]: {},
        [BOM_VALIDATION_FILTER.DATE.key]: {}
      },
      currentDate: new Date().toISOString().substring(0, 10)
    }),
    computed: {
      ...mapState('validations', ['isBomValidationLoading']),
      ...mapState(['user'])
    },
    methods: {
      ...mapActions('validations', ['getBomValidationsList']),
      ...mapMutations('validations',
                      {
                        SET_CURRENT_BOM_VALIDATION,
                        SET_IN_PROGRESS_BOM_VALIDATIONS,
                        SET_BOM_VALIDATION_IS_LOADING,
                        SET_FILTERED_USER_VALIDATIONS,
                        HANDLE_INITIAL_STATE_SELECTED_EXPORT,
                        SET_FILTER_USER_VALIDATIONS
                      }),

      async refreshBOMValidations() {
        this.SET_BOM_VALIDATION_IS_LOADING(true)

        try {
          this.checkBomValidationsStatus()
          await this.getBomValidationsList()
        }
        finally {
          this.SET_BOM_VALIDATION_IS_LOADING(false)
        }
      },
      checkBomValidationsStatus() {
        if (!state.bomValidationsInProgress?.length) return
        const changedStatus = getters.getChangedStatus(this.tempBomValidationsInProgress)
        if (changedStatus.length) recordAnalyticsMulti(BOM_UPDATE_STATUS, changedStatus)
      },
      handleClick() {
        this.$emit('clicked-refresh-btn', true)
      },
      updateActiveFilter(type) {
        if (type === undefined || type === '') return ''
        const filterType = (VALIDATIONS_FILTERS.USER.key === type)
          ? VALIDATIONS_FILTERS.USER.key
          : VALIDATIONS_FILTERS.DATE.key

        const hasFilter = this.activeFilters.has(filterType)

        hasFilter ? this.activeFilters.delete(filterType) : this.activeFilters.set(filterType)

        return filterType
      },
      handleFilter(type, val) {
        const newFilter = this.updateActiveFilter(type, val)

        if (newFilter) this.setFilters(type, val)

        this.SET_FILTERED_USER_VALIDATIONS()
        this.HANDLE_INITIAL_STATE_SELECTED_EXPORT()
      },
      setFilters(type, val) {
        const filterOptions = {
          [BOM_VALIDATION_FILTER.USER.key]: { ...BOM_VALIDATION_FILTER.USER, value: val.toString() },
          [BOM_VALIDATION_FILTER.DATE.key]: { ...BOM_VALIDATION_FILTER.DATE, value: val.toString() }
        }

        this.SET_FILTER_USER_VALIDATIONS(filterOptions[type])
      }
    }
  }
</script>

<style lang="scss">
  .validations-filter {
    &__refresh-btn {
      width: 48px;
      height: 48px;
      padding-right: 1rem;
    }

    &__divider {
      border: 1px solid $mi-anthracite-50;
      height: 27px;
    }

    &__filter-btn {
      margin: 0 16px;

      &--selected {
        background-color: $mi-anthracite-800;
        /* stylelint-disable declaration-no-important */
        color: #ffffff !important;
      }

      &--my-files {
        width: 250px;
      }

      &--today-files {
        width: 210px;
      }
    }

    &__basic-filter-btn {
      margin-left: 0;
    }
  }
</style>
